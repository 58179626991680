





















import accountStateRef from "@/apps/accounts/modules/store";
import { computed, defineComponent } from "@vue/composition-api";
import { truncate } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "DiagnosisTag",
  props: {
    aktivitasDiagnosis: { type: Object, required: true },
  },
  setup(props) {
    const konsulenId = accountStateRef.me.anggota?.konsulen?.id ?? "";
    const residenId = accountStateRef.me.anggota?.residen?.id ?? "";

    const komponenClass = computed(() => {
      const aktDiag = props.aktivitasDiagnosis;
      if (aktDiag.utama) {
        return "is-danger";
      } else if (
        aktDiag.aktivitasresidenResidenId == residenId ||
        aktDiag.aktivitasresidenDpjpId == konsulenId
      ) {
        return "is-success";
      }
      return "is-info";
      }
    );

    const iconMap: Record<string, string> = {
      Ya: "check",
      Tidak: "times",
    };

    const getIcon = (verifikasi: string) => {
      return iconMap[verifikasi];
    };

    const tagType = computed(() =>
      props.aktivitasDiagnosis.verifikasi === "Tidak"
        ? "is-danger"
        : "is-success"
    );

    return {
      // Computed
      komponenClass,
      tagType,

      // Method
      truncate,
      getIcon,
    };
  },
});
